const binding = { modules: {}, dataActions: {} };

    const registerActionId = (actionPath) => {
        if (binding.dataActions[actionPath] &&
            binding.dataActions[actionPath].default &&
            binding.dataActions[actionPath].default.prototype &&
            binding.dataActions[actionPath].default.prototype.id) {
            binding.dataActions[binding.dataActions[actionPath].default.prototype.id] = binding.dataActions[actionPath];
        } else {
            Object.keys(binding.dataActions[actionPath] || {}).forEach(exportName => {
                if (binding.dataActions[actionPath][exportName] &&
                    binding.dataActions[actionPath][exportName].prototype &&
                    binding.dataActions[actionPath][exportName].prototype.Action) {
                    binding.dataActions[binding.dataActions[actionPath][exportName].prototype.id] = binding.dataActions[actionPath][exportName];
                }
            })
        }
    };

    const registerSanitizedActionPath = (sanitizedActionPath, dataAction) => {
        if (process.env.NODE_ENV === 'development') {
            if (!dataAction.default) {
                throw new Error('Data action path does not have a default export');
            }
            if (!(dataAction.default.prototype.id && binding.dataActions[dataAction.default.prototype.id]) || !binding.dataActions[sanitizedActionPath]) {
                binding.dataActions[sanitizedActionPath] = dataAction;
            }
        } else {
            binding.dataActions[sanitizedActionPath] = dataAction;
            if (!binding.dataActions[sanitizedActionPath].default) {
                throw new Error('Data action path ' + sanitizedActionPath + ' does not have a default export');
            }
            binding.dataActions[sanitizedActionPath].default.prototype.RegistrationId = sanitizedActionPath;
            if (binding.dataActions[sanitizedActionPath].default.prototype.id) {
                binding.dataActions[binding.dataActions[sanitizedActionPath].default.prototype.id] = sanitizedActionPath;
            }
        }
    };
    

        {   
            const actionPath = 'actions/applepay-payment-connector-loader.action';
            binding.dataActions[actionPath] = require('partner/actions/applepay-payment-connector-loader.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/bedhub-footer.action';
            binding.dataActions[actionPath] = require('partner/actions/bedhub-footer.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/bedhub-header.action';
            binding.dataActions[actionPath] = require('partner/actions/bedhub-header.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/bonus-coupon-details.action';
            binding.dataActions[actionPath] = require('partner/actions/bonus-coupon-details.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/bonus-item-product.action';
            binding.dataActions[actionPath] = require('partner/actions/bonus-item-product.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/checkout-billing-custom-errors-validator.action';
            binding.dataActions[actionPath] = require('partner/actions/checkout-billing-custom-errors-validator.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/checkout-billing-validity.action';
            binding.dataActions[actionPath] = require('partner/actions/checkout-billing-validity.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/checkout-disabled-button-state.action';
            binding.dataActions[actionPath] = require('partner/actions/checkout-disabled-button-state.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/complete-your-bed-pillow.action';
            binding.dataActions[actionPath] = require('partner/actions/complete-your-bed-pillow.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/complete-your-bed-protector.action';
            binding.dataActions[actionPath] = require('partner/actions/complete-your-bed-protector.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/express-edit-state.action';
            binding.dataActions[actionPath] = require('partner/actions/express-edit-state.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/express-order-submit-state.action';
            binding.dataActions[actionPath] = require('partner/actions/express-order-submit-state.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/fetch-choose-your-foundation-database.action';
            binding.dataActions[actionPath] = require('partner/actions/fetch-choose-your-foundation-database.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/fetch-complete-your-bed.action';
            binding.dataActions[actionPath] = require('partner/actions/fetch-complete-your-bed.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/fetch-core-delivery-list.action';
            binding.dataActions[actionPath] = require('partner/actions/fetch-core-delivery-list.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/get-delivery-fee-update.action';
            binding.dataActions[actionPath] = require('partner/actions/get-delivery-fee-update.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/get-delivery-message.action';
            binding.dataActions[actionPath] = require('partner/actions/get-delivery-message.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/get-eyebrow-message.action';
            binding.dataActions[actionPath] = require('partner/actions/get-eyebrow-message.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/get-mattress-foundations-to-add.action';
            binding.dataActions[actionPath] = require('partner/actions/get-mattress-foundations-to-add.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/get-mfrm-products-category-paths.action';
            binding.dataActions[actionPath] = require('partner/actions/get-mfrm-products-category-paths.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/get-recycle-fee-checkout.action';
            binding.dataActions[actionPath] = require('partner/actions/get-recycle-fee-checkout.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/get-sm-inventory-status.action';
            binding.dataActions[actionPath] = require('partner/actions/get-sm-inventory-status.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/get-soci-data.action';
            binding.dataActions[actionPath] = require('partner/actions/get-soci-data.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/get-try-in-store-info.action';
            binding.dataActions[actionPath] = require('partner/actions/get-try-in-store-info.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/get-unbxd-product-recommendations.action';
            binding.dataActions[actionPath] = require('partner/actions/get-unbxd-product-recommendations.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/payment-redirect-to-order-confirmation.action';
            binding.dataActions[actionPath] = require('partner/actions/payment-redirect-to-order-confirmation.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/paypal-express-edit-state.action';
            binding.dataActions[actionPath] = require('partner/actions/paypal-express-edit-state.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/paypal-payment-connector-loader.action';
            binding.dataActions[actionPath] = require('partner/actions/paypal-payment-connector-loader.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/paypalexpress-tryagain-trigger.action';
            binding.dataActions[actionPath] = require('partner/actions/paypalexpress-tryagain-trigger.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/paypalsuccesstrigger.action';
            binding.dataActions[actionPath] = require('partner/actions/paypalsuccesstrigger.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/progressive-actions/Lease-Lookup.action';
            binding.dataActions[actionPath] = require('partner/actions/progressive-actions/Lease-Lookup.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/progressive-actions/Progressive-address-mismatch.action';
            binding.dataActions[actionPath] = require('partner/actions/progressive-actions/Progressive-address-mismatch.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/progressive-actions/Progressive-get-payment-estimate.action';
            binding.dataActions[actionPath] = require('partner/actions/progressive-actions/Progressive-get-payment-estimate.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/progressive-actions/Progressive-get-pricing-info.action';
            binding.dataActions[actionPath] = require('partner/actions/progressive-actions/Progressive-get-pricing-info.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/progressive-actions/Progressive-submit-cart.action';
            binding.dataActions[actionPath] = require('partner/actions/progressive-actions/Progressive-submit-cart.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/progressive-actions/progressive-delete-transaction.action';
            binding.dataActions[actionPath] = require('partner/actions/progressive-actions/progressive-delete-transaction.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/progressive-actions/progressive-get-lease-contract.action';
            binding.dataActions[actionPath] = require('partner/actions/progressive-actions/progressive-get-lease-contract.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/progressive-actions/progressive-set-lease-lookup-info.action';
            binding.dataActions[actionPath] = require('partner/actions/progressive-actions/progressive-set-lease-lookup-info.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/progressive-actions/progressive-set-pricing-info.action';
            binding.dataActions[actionPath] = require('partner/actions/progressive-actions/progressive-set-pricing-info.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/select-your-foundation.action';
            binding.dataActions[actionPath] = require('partner/actions/select-your-foundation.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/showloading-continuetodelivery.action';
            binding.dataActions[actionPath] = require('partner/actions/showloading-continuetodelivery.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/social-icons-state.action';
            binding.dataActions[actionPath] = require('partner/actions/social-icons-state.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/srp-breadcrumb-page.action';
            binding.dataActions[actionPath] = require('partner/actions/srp-breadcrumb-page.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/trigger-special-synchrony-financing.action';
            binding.dataActions[actionPath] = require('partner/actions/trigger-special-synchrony-financing.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/update-monthly-payment.action';
            binding.dataActions[actionPath] = require('partner/actions/update-monthly-payment.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/user-defined-promocode.action';
            binding.dataActions[actionPath] = require('partner/actions/user-defined-promocode.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/wmap-address-state.action';
            binding.dataActions[actionPath] = require('partner/actions/wmap-address-state.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/zip-code-state.action';
            binding.dataActions[actionPath] = require('partner/actions/zip-code-state.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'actions/zip-code-validation.action';
            binding.dataActions[actionPath] = require('partner/actions/zip-code-validation.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'data-actions/atp-slots-information-state.action';
            binding.dataActions[actionPath] = require('partner/data-actions/atp-slots-information-state.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'data-actions/bonuscoupons-action.action';
            binding.dataActions[actionPath] = require('partner/data-actions/bonuscoupons-action.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'data-actions/brightspot-content-action.action';
            binding.dataActions[actionPath] = require('partner/data-actions/brightspot-content-action.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'data-actions/checkout-skeleton.action';
            binding.dataActions[actionPath] = require('partner/data-actions/checkout-skeleton.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'data-actions/financing-offer-state.action';
            binding.dataActions[actionPath] = require('partner/data-actions/financing-offer-state.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'data-actions/get-address-from-zipcode.action';
            binding.dataActions[actionPath] = require('partner/data-actions/get-address-from-zipcode.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'data-actions/get-card-payment-accept-point.override.action';
            binding.dataActions[actionPath] = require('partner/data-actions/get-card-payment-accept-point.override.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'data-actions/get-comfort-type.action';
            binding.dataActions[actionPath] = require('partner/data-actions/get-comfort-type.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'data-actions/get-franchise-data.action';
            binding.dataActions[actionPath] = require('partner/data-actions/get-franchise-data.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'data-actions/get-product-benefit-enhanced-content.action';
            binding.dataActions[actionPath] = require('partner/data-actions/get-product-benefit-enhanced-content.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'data-actions/get-product-benefits-data.action';
            binding.dataActions[actionPath] = require('partner/data-actions/get-product-benefits-data.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'data-actions/get-resons-to-buy-data.action';
            binding.dataActions[actionPath] = require('partner/data-actions/get-resons-to-buy-data.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'data-actions/get-sales-order-details-action.action';
            binding.dataActions[actionPath] = require('partner/data-actions/get-sales-order-details-action.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'data-actions/get-selected-variant.override.action';
            binding.dataActions[actionPath] = require('partner/data-actions/get-selected-variant.override.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'data-actions/get-seo-data.action';
            binding.dataActions[actionPath] = require('partner/data-actions/get-seo-data.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'data-actions/get-sleep-disruptor-icons.action';
            binding.dataActions[actionPath] = require('partner/data-actions/get-sleep-disruptor-icons.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'data-actions/mfi-atp-inventory.action';
            binding.dataActions[actionPath] = require('partner/data-actions/mfi-atp-inventory.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'data-actions/mfrm-mfi-bonusitem.action';
            binding.dataActions[actionPath] = require('partner/data-actions/mfrm-mfi-bonusitem.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'data-actions/mfrm-notification.action';
            binding.dataActions[actionPath] = require('partner/data-actions/mfrm-notification.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'data-actions/payment-cc-card-types.action';
            binding.dataActions[actionPath] = require('partner/data-actions/payment-cc-card-types.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'data-actions/payment-error-state.action';
            binding.dataActions[actionPath] = require('partner/data-actions/payment-error-state.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'data-actions/products-by-category.override.action';
            binding.dataActions[actionPath] = require('partner/data-actions/products-by-category.override.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'data-actions/products-get-by-id.action';
            binding.dataActions[actionPath] = require('partner/data-actions/products-get-by-id.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'data-actions/progressive-leasing-modal-state.action';
            binding.dataActions[actionPath] = require('partner/data-actions/progressive-leasing-modal-state.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'data-actions/promocode-applied-in-progress.action';
            binding.dataActions[actionPath] = require('partner/data-actions/promocode-applied-in-progress.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'data-actions/redirect-to-wmap-submit-payment.action';
            binding.dataActions[actionPath] = require('partner/data-actions/redirect-to-wmap-submit-payment.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'data-actions/search-org-unit-locations.action';
            binding.dataActions[actionPath] = require('partner/data-actions/search-org-unit-locations.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'data-actions/toggle-submit-order-button.action';
            binding.dataActions[actionPath] = require('partner/data-actions/toggle-submit-order-button.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'data-actions/update-email-call.action';
            binding.dataActions[actionPath] = require('partner/data-actions/update-email-call.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'data-actions/wmap-get-order-details.action';
            binding.dataActions[actionPath] = require('partner/data-actions/wmap-get-order-details.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'data-actions/wmap-submit-payment.action';
            binding.dataActions[actionPath] = require('partner/data-actions/wmap-submit-payment.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'modules/mfrm-autosuggest/actions/get-unbxd-search-autosuggest.action';
            binding.dataActions[actionPath] = require('partner/modules/mfrm-autosuggest/actions/get-unbxd-search-autosuggest.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'modules/mfrm-category-page-summary/mfrm-category-page-summary.action';
            binding.dataActions[actionPath] = require('partner/modules/mfrm-category-page-summary/mfrm-category-page-summary.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'modules/mfrm-product-page-summary/get-product-recordId.action';
            binding.dataActions[actionPath] = require('partner/modules/mfrm-product-page-summary/get-product-recordId.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'modules/mfrm-product-page-summary/mfrm-product-page-summary.action';
            binding.dataActions[actionPath] = require('partner/modules/mfrm-product-page-summary/mfrm-product-page-summary.action');
            registerActionId(actionPath);
        }

        {   
            const actionPath = 'modules/mfrm-products-compare/get-unbxd-collection-compare-data.action';
            binding.dataActions[actionPath] = require('partner/modules/mfrm-products-compare/get-unbxd-collection-compare-data.action');
            registerActionId(actionPath);
        }

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.packageDataActions = {};
        window.__bindings__.packageDataActions['__local__'] = {
            ...binding.dataActions
        };