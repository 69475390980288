import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
export interface IUpdateEmailCart {
    checkEmail: boolean | null;
}

/**
 * SampleState - action input
 */
export class UpdateEmailCartInput implements IActionInput {
    public getCacheKey = () => `UpdateEmailCartInput`;
    public getCacheObjectType = () => 'UpdateEmailCartInput';
    public dataCacheType = (): CacheType => 'request';
}

/**
 * SampleState - action
 */
export async function updateEmailCartAction(input: UpdateEmailCartInput, ctx: IActionContext): Promise<IUpdateEmailCart> {
    return { checkEmail: null };
}

/**
 * SampleState - create new input for create action
 */
const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    return new UpdateEmailCartInput();
};

/**
 * SampleState - create action
 */
export default createObservableDataAction<IUpdateEmailCart>({
    id: 'UpdateEmailCart',
    action: <IAction<IUpdateEmailCart>>updateEmailCartAction,
    input: createInput
});