import * as Msdyn365 from '@msdyn365-commerce/core';
export async function getAccessToken(baseURL: string, ctx: Msdyn365.IRequestContext) {
    const clientId: Msdyn365.ISecretValue | undefined = await Msdyn365.msdyn365Commerce.secretManager?.getSecretValue(
      'bh-client-id',
      ctx.apiSettings.baseUrl
    );
    const clientSecret: Msdyn365.ISecretValue | undefined = await Msdyn365.msdyn365Commerce.secretManager?.getSecretValue(
      'bh-client-secret',
      ctx.apiSettings.baseUrl
    );
    const tenant: Msdyn365.ISecretValue | undefined = await Msdyn365.msdyn365Commerce.secretManager?.getSecretValue(
      'bh-tenant-id',
      ctx.apiSettings.baseUrl
    );
    if (process.env.CURRENT_ENVIRONMENT === 'node') {
      const msalConfig = {
        auth: {
          authority: new URL(tenant?.value ?? '', 'https://login.microsoftonline.com').href,
          clientId: clientId?.value ?? '',
          clientSecret: clientSecret?.value ?? '',
          tenantId: tenant?.value ?? ''
        }
      };
      // eslint-disable-next-line @typescript-eslint/no-require-imports
      const ConfidentialClientApplication = require('@azure/msal-node').ConfidentialClientApplication;
      const msalInstance = new ConfidentialClientApplication(msalConfig);
      const token = await msalInstance.acquireTokenByClientCredential({ scopes: [new URL('.default', baseURL).href] });
      return token?.accessToken;
    }
    return '';
  }