// sample-state.ts
import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
export interface IBillingState {
    isBillingFormValid: boolean;
}

/**
 * BillingState - action input
 */
export class BillingStateInput implements IActionInput {
    public getCacheKey = () => `BillingState`;
    public getCacheObjectType = () => 'BillingState';
    public dataCacheType = (): CacheType => 'request';
}

/**
 * BillingState - action
 */
export async function BillingStateAction(input: BillingStateInput, ctx: IActionContext): Promise<IBillingState> {
    return { isBillingFormValid: false};
}

/**
 * BillingState - create new input for create action
 */
const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    return new BillingStateInput();
};

/**
 * BillingState - create action
 */
export default createObservableDataAction<IBillingState>({
    id: 'BillingState',
    action: <IAction<IBillingState>>BillingStateAction,
    input: createInput
});