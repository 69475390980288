// sample-state.ts
import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
export interface IBillingCustomError {
    billingHasCustomError: boolean;
}

/**
 * BillingCustomError - action input
 */
export class billingCustomErrorInput implements IActionInput {
    public getCacheKey = () => `billingCustomErrors`;
    public getCacheObjectType = () => 'billingCustomErrors';
    public dataCacheType = (): CacheType => 'request';
}

/**
 * BillingCustomError -  action
 */
export async function billingCustomErrorAction(input: billingCustomErrorInput, ctx: IActionContext): Promise<IBillingCustomError> {
    return { billingHasCustomError: false};
}

/**
 * BillingCustomError - create new input for create action
 */
const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    return new billingCustomErrorInput();
};

/**
 * BillingCustomError - create action
 */
export default createObservableDataAction<IBillingCustomError>({
    id: 'BillingCustomErrors',
    action: <IAction<IBillingCustomError>>billingCustomErrorAction,
    input: createInput
});