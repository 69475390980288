// sample-state.ts
import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    IAny,
    ICreateActionContext,
    IGeneric
} from '@msdyn365-commerce/core';
export interface IZipCodeState {
    isStateValid: boolean;
}

/**
 * ZipCodeState - action input
 */
export class ZipCodeStateInput implements IActionInput {
    public getCacheKey = () => `ZipCodeState`;
    public getCacheObjectType = () => 'ZipCodeState';
    public dataCacheType = (): CacheType => 'request';
}

/**
 * ZipCodeState - action
 */
export async function ZipCodeStateAction(input: ZipCodeStateInput, ctx: IActionContext): Promise<IZipCodeState> {
    return {isStateValid: false };
}

/**
 * ZipCodeState - create new input for create action
 */
const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    return new ZipCodeStateInput();
};

/**
 * ZipCodeState - create action
 */
export default createObservableDataAction<IZipCodeState>({
    id: 'ZipCodeState',
    action: <IAction<IZipCodeState>>ZipCodeStateAction,
    input: createInput
});
