import { buildCacheKey, getSimpleProducts, ProductInput } from '@msdyn365-commerce-modules/retail-actions';
import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, ICommerceApiSettings, ICreateActionContext } from '@msdyn365-commerce/core';
import { ProductDeliveryOptions, SalesOrder, SimpleProduct, TransactionType } from '@msdyn365-commerce/retail-proxy';
import { getDeliveryOptionsAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { getSalesOrderDetailsBySalesIdAsync, getSalesOrderDetailsByTransactionIdAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/SalesOrdersDataActions.g';

interface ISalesOrderWithHydrations {
    salesOrder: SalesOrder;
    products: SimpleProduct[];
    productDeliveryOptions?: ProductDeliveryOptions[];
}

/**
 *  orderTypes Types of orders
 */
export const enum orderTypes {
    salesOrder = 'salesOrder',
    transaction = 'transaction'
}

/**
 * Calls the Retail API and returns the sales order
 */
const getSalesOrder = (orderType: string = '', orderId: string = '') => async (ctx: IActionContext): Promise<SalesOrder> => {
    return orderType === orderTypes.salesOrder
        ? getSalesOrderDetailsBySalesIdAsync({ callerContext: ctx }, orderId)
        : //  Local (1) searches the retail server database, and remote (2) searches
          // on the headquarters side. All (3) and none (0) are not supported.
          getSalesOrderDetailsByTransactionIdAsync({ callerContext: ctx }, orderId, 1);
};

/**
 * Calls the Retail API and returns the products
 */
const getProducts = (productIds: number[] = [], channelId?: number) => async (ctx: IActionContext): Promise<SimpleProduct[]> => {
    const productInputs = productIds.map(productId => new ProductInput(productId, ctx.requestContext.apiSettings, channelId));
    return getSimpleProducts(productInputs, ctx);
};

/**
 *  Action input
 */
export class GetSalesOrderWithHydrationsInput implements IActionInput {
    public orderType: string;
    public orderId: string;
    private apiSettings: ICommerceApiSettings;

    constructor(orderType: string , orderId: string, apiSettings: ICommerceApiSettings) {
        this.orderType = orderType;
        this.orderId = orderId;
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => buildCacheKey(`SalesOrderWithHydrations`, this.apiSettings);
    public getCacheObjectType = () => `SalesOrderWithHydrations-${this.orderType}-${this.orderId}`;
    public dataCacheType = (): CacheType => 'request';
}

/**
 * Creates the input required to make the retail api call
 */
const createSalesOrderWithHydrationsInput = (inputData: ICreateActionContext) => {
    const { salesId = '', transactionId = '' } = (inputData.requestContext.query && inputData.requestContext.query) || {};
    if (salesId) {
        return new GetSalesOrderWithHydrationsInput(orderTypes.salesOrder, salesId, inputData.requestContext.apiSettings);
    } else if (transactionId) {
        return new GetSalesOrderWithHydrationsInput(orderTypes.transaction, transactionId, inputData.requestContext.apiSettings);
    }
    throw new Error(`createSalesOrderWithHydrationsInput - No salesId or transactionId provided.`);
};

/**
 * Get sales order with hydrations action
 */
export async function getSalesOrderWithHydrationsAction(
    input: GetSalesOrderWithHydrationsInput,
    ctx: IActionContext
): Promise<ISalesOrderWithHydrations> {
    if (!ctx) {
        throw new Error(`getSalesOrderWithHydrationsAction - Action context cannot be null/undefined`);
    }

    const { orderType, orderId } = input;

    if (!orderType || !orderId) {
        ctx.trace(`[getSalesOrderWithHydrationsAction] No orderType or orderId provided.`);
        return <ISalesOrderWithHydrations>{};
    }

    const salesOrder = await getSalesOrder(orderType, orderId)(ctx);

    if (!salesOrder) {
        ctx.trace(`[getSalesOrderWithHydrationsAction] No salesOrder found.`);
        return <ISalesOrderWithHydrations>{};
    }

    // If it is salesInvoice, return salesOrder and empty products
    // tslint:disable-next-line:prefer-type-cast
    if (salesOrder.TransactionTypeValue === 15 as TransactionType.SalesInvoice) {
        return <ISalesOrderWithHydrations>{
            salesOrder,
            products: []
        };
    }

    if (!salesOrder.SalesLines || !salesOrder.SalesLines.length) {
        ctx.trace(`[getSalesOrderWithHydrationsAction] No salesLine found.`);
        return <ISalesOrderWithHydrations>{};
    }

    const productIds: number[] = salesOrder.SalesLines.map(salesLine => salesLine.ProductId || 0).filter(productId => productId);

    if (!productIds || !productIds.length) {
        ctx.trace(`[getSalesOrderWithHydrationsAction] No productId in saleLines found.`);
        return <ISalesOrderWithHydrations>{};
    }

    const products = await getProducts(productIds, salesOrder.ChannelId)(ctx);
    if (!products || !products.length) {
        ctx.trace(`[getSalesOrderWithHydrationsAction] No product found.`);
        return <ISalesOrderWithHydrations>{};
    }

    const deliveryOptions =  await getDeliveryOptionsAsync({ callerContext: ctx, queryResultSettings: {} }, products.map(x => x.RecordId), {}, 4)
        .then(result => {
        return result;
    }).catch((error: Error) => {
        ctx.trace(error.message);
        ctx.telemetry.exception(error);
        ctx.telemetry.debug(`[getDeliveryOptionsForCartLineItems]Error executing action`);
        throw new Error('[getDeliveryOptionsForCartLineItems]Error executing action');
    });

    return <ISalesOrderWithHydrations>{
        salesOrder,
        products,
        deliveryOptions
    };
}

export const getSalesOrderWithHydrationsActionDataAction =  createObservableDataAction({
    id: 'mfrm-get-sales-order-with-hydrations',
    action: <IAction<ISalesOrderWithHydrations>>getSalesOrderWithHydrationsAction,
    input: createSalesOrderWithHydrationsInput
});

export default getSalesOrderWithHydrationsActionDataAction;