import * as Msdyn365 from '@msdyn365-commerce/core';

export interface ISRPBreadcrumbState {
    breadcrumbCheck: boolean;
}

/**
 * SrpBreadcrumbPage Input Action
 */
export class SrpBreadcrumbPageInput implements Msdyn365.IActionInput {
    //   Construct the input needed to run the action
    //   Determine if the results of this get action should cache the results and if so provide
    // a cache object type and an appropriate cache key
    public getCacheKey = () => `BreadcrumbSrp`;
    public getCacheObjectType = () => 'BreadcrumbSrp';
    public dataCacheType = (): Msdyn365.CacheType => 'application';
}

//   Create a data model here or import one to capture the response of the action
export interface ISrpBreadcrumbPageData {
    text: string;
}

/**
 *   Use this function to create the input required to make the action call
 */
const createInput = (args: Msdyn365.ICreateActionContext): Msdyn365.IActionInput => {
    return new SrpBreadcrumbPageInput();
};

/**
 *   Use this function to call your action and process the results as needed
 */
async function action(input: SrpBreadcrumbPageInput, ctx: Msdyn365.IActionContext): Promise<ISRPBreadcrumbState> {
    // const apiSettings = Msdyn365.msdyn365Commerce.apiSettings;

    //   Uncomment the below line to get the value from a service
    // const response = await Msdyn365.sendRequest<ISrpBreadcrumbPageData[]>('/get/example/id/1', 'get');
    return { breadcrumbCheck: false };
}

export default Msdyn365.createObservableDataAction({
    action: <Msdyn365.IAction<ISRPBreadcrumbState>>action,
    //   Give your data action a unique id
    id: 'SrpBreadcrumbPage',
    input: createInput
    //   Uncomment the below line if this is a meant to be a batched data action
    // isBatched: true
});
