import * as Msdyn365 from '@msdyn365-commerce/core';
import { mFIGetPricingInformationAsync, IGetPricingInformationRequet, IGetPricingInformationResponse, IMFIGetPricingInformationResponse } from './progressive-proxy-files';

/**
 * GetPricingInformation Input Action
 */

export class GetPricingInformationInput extends Msdyn365.CommerceEntityInput implements Msdyn365.IActionInput {
    public leasePricingInformationPayload: IGetPricingInformationRequet;

    constructor(leasePricingInformation: IGetPricingInformationRequet) {
        super({ shouldCacheOutput: true, cacheObjectType: 'GetPricingInformation', cacheKey: 'GetPricingInformation' });
        this.leasePricingInformationPayload = leasePricingInformation;
    }

    public getCacheKey = () => `GetPricingInformation`;
    public getCacheObjectType = () => 'GetPricingInformation';
    public dataCacheType = (): Msdyn365.CacheType => 'application';
}

const createInput = (args: Msdyn365.ICreateActionContext): Msdyn365.IActionInput => {
    return new GetPricingInformationInput({ ApplicationId: '' });
};

/**
 * GetPricingInformationAction function receives applicationId and returns details
 */
export async function GetPricingInformationAction(input: GetPricingInformationInput, ctx: Msdyn365.IActionContext): Promise<IGetPricingInformationResponse | null> {
    try {
        const leasePricingInformationResponse: IMFIGetPricingInformationResponse = await mFIGetPricingInformationAsync({ callerContext: ctx }, input.leasePricingInformationPayload);
        return leasePricingInformationResponse.PricingInformation ?? null;
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
}

export const IGetPricingInformationAction = Msdyn365.createObservableDataAction({
    action: <Msdyn365.IAction<IGetPricingInformationResponse>>GetPricingInformationAction,
    input: createInput,
    id: 'GetPricingInformation'
});