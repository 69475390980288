import { IProductRefinerHierarchy } from '@msdyn365-commerce/commerce-entities';
import { IRequestContext } from '@msdyn365-commerce/core';
import { ProductRefinerValue } from '@msdyn365-commerce/retail-proxy';
export interface IVirtualRefinersConfig {
    order: number;
    refinerName: string;
    enableForMattresses: boolean;
    enableForOtherCategories: boolean;
    allowDuplicate: boolean;
}

/**
 * This will create the canonicalURL as per 7924 requirement
 * @param requestContext
 * @returns string of canonical URL
 */
export const canonicalURL = (requestContext: IRequestContext): string => {
    const requestUrl = requestContext.url.requestUrl;
    const origin = requestUrl.origin;
    const path = requestUrl.pathname.toLowerCase();

    // separate out url parts split on forward / and remove first empty index
    let urlParts = path?.split('/').splice(1);

    // remove double values containing pipe sign
    if (path.match(/%7c/)) {
        urlParts = removePipeSignValue(urlParts);
    }

    // remove unwanted keywords from url
    urlParts = removeKeywords(urlParts, requestContext);

    const filteredPath = urlParts?.join('/');

    return `${origin}/${filteredPath}`;
};

export const filteredURL = (requestContext: IRequestContext): string => {
    const requestUrl = requestContext.url.requestUrl;
    const path = requestUrl.pathname.toLowerCase();

    // separate out url parts split on forward / and remove first empty index
    let urlParts = path?.split('/').splice(1);

    // remove unwanted keywords from url
    urlParts = removeKeywords(urlParts, requestContext);

    const filteredPath = urlParts?.join('/');

    return `/${filteredPath}`;
};

/**
 * This will create the canonicalURL as per 7924 requirement
 * @param requestContext
 * @returns string of canonical URL
 */
export const canonicalURLWithoutBaseUrl = (requestContext: IRequestContext): string => {
    const requestUrl = requestContext.url.requestUrl;
    const path = requestUrl.pathname.toLowerCase();

    // separate out url parts split on forward / and remove first empty index
    let urlParts = path?.split('\/').splice(1);

    // remove double values containing pipe sign
    if (path.match(/%7c/)) {
        urlParts = removePipeSignValue(urlParts);
    }

    // remove unwanted keywords from url
    urlParts = removeKeywords(urlParts, requestContext);

    return urlParts?.join('\/');
};

/**
 * This will remove the Pipe sign value set from urlParts
 * @param urlParts
 * @returns
 */
export const removePipeSignValue = (urlParts: string[]) => {
    // urlParts.forEach((part, index) => {
    //     const indexOfValue = urlParts.indexOf('%7c');
    //     if (indexOfValue !== -1) {
    //         urlParts.splice(indexOfValue, 1);
    //     }
    // });

    if (urlParts !== undefined && urlParts.length > 0) {
        urlParts = urlParts.filter(urlPart => urlPart.indexOf('%7c') === -1);
    }

    return urlParts;
};

/**
 * This will remove the keywords from urlParts array which were received from
 * the app.settings.json/CMS Extensions
 * @param urlParts
 * @param requestContext
 * @returns urlParts array filtered
 */
export const removeKeywords = (urlParts: string[], requestContext: IRequestContext): string[] => {
    const removeKeywordFromUrl = requestContext.app.config.removeKeywordFromUrl;

    if (urlParts.length > 0 && removeKeywordFromUrl && removeKeywordFromUrl.length > 0) {
        const removeKeywordFromUrlArray: string[] = removeKeywordFromUrl?.trim().split(',');
        removeKeywordFromUrlArray?.length > 0 &&
            urlParts.forEach(_part => {
                removeKeywordFromUrlArray.forEach(removeKeyword => {
                    const trimmedKeyword = removeKeyword.trim().toLowerCase();
                    const indexOfValue = urlParts.indexOf(trimmedKeyword);
                    if (trimmedKeyword.length > 0 && indexOfValue !== -1) {
                        urlParts.splice(indexOfValue, 1);
                    }
                });
            });
    }

    return urlParts;
};

/**
 * This will sort the Virtual refiner config based on order and return
 * only active virtual refiner configs based on isMattress category will
 * be returned
 * @param requestContext
 * @param isMattressesCategory boolean | undefined
 * @returns IVirtualRefinersConfig[] | undefined
 */
export const getSortedVirtualRefinerConfig = (
    requestContext: IRequestContext,
    isMattressesCategory: boolean | undefined
): IVirtualRefinersConfig[] | undefined => {
    let appConfigSorted = getVirtualRefinerConfig(requestContext);

    // sort the config by provided config
    if (appConfigSorted) {
        if (isMattressesCategory === true) {
            appConfigSorted = appConfigSorted.filter(appConfig => {
                return appConfig.enableForMattresses;
            });
        } else if (isMattressesCategory === false) {
            appConfigSorted = appConfigSorted.filter(appConfig => {
                return appConfig.enableForOtherCategories;
            });
        }
        appConfigSorted.sort((a: IVirtualRefinersConfig, b: IVirtualRefinersConfig) => {
            return a.order - b.order;
        });
    }

    return appConfigSorted;
};

/**
 * Virtual refiner names from the app.settings.json will be returned which can be used
 * to filter out refiners data based upon active virtual refiners
 * @param requestContext
 * @param isMattressesCategory boolean | undefined
 * @returns string[]
 */
export const getVirtualRefinerNames = (requestContext: IRequestContext, isMattressesCategory: boolean | undefined): string[] => {
    const sortedVirtualRefinerConfig = getSortedVirtualRefinerConfig(requestContext, isMattressesCategory);

    const appConfigRefinerNames: string[] = [];

    sortedVirtualRefinerConfig &&
        sortedVirtualRefinerConfig.forEach(sortedConfig => {
            appConfigRefinerNames.push(sortedConfig.refinerName.trim().toLowerCase());
        });

    return appConfigRefinerNames;
};

/**
 * This will return the value of virtual refiner configuration
 * @param requestContext
 * @returns IVirtualRefinersConfig[] | undefined
 */
export const getVirtualRefinerConfig = (requestContext: IRequestContext): IVirtualRefinersConfig[] => {
    return requestContext.app.config.urlModifications;
};

export async function getVirtualRefinerValues(
    requestContext: IRequestContext,
    virtualRefiners: IProductRefinerHierarchy[]
): Promise<ProductRefinerValue[]> {
    const virtualRefinerValues: ProductRefinerValue[] = [];
    if (virtualRefiners.length === 0) {
        return virtualRefinerValues;
    }
    const requestUrl = requestContext.url.requestUrl;
    // const origin = requestUrl.origin;
    const path = requestUrl.pathname;

    // separate out url parts split on forward / and remove first empty index
    let urlParts = path?.split('/').splice(1);

    // remove unwanted keywords from url
    urlParts = removeKeywords(urlParts, requestContext);

    // remove category id {categoryid}.c
    const categoryIdToken = requestContext.urlTokens.recordId;
    const categoryId = categoryIdToken ? `${categoryIdToken}.c` : undefined;

    if (categoryId) {
        urlParts.splice(-1);
    }

    // run loop for each url part and get values from refiners for further usage
    const finalizedUrlParts: string[] = [];
    urlParts.forEach(plainUrlPart => {
        const urlPart = decodeURIComponent(plainUrlPart);
        if (urlPart.indexOf('|') !== -1) {
            const splitOnPipeSign = urlPart?.split('|');
            const filteredParts = splitOnPipeSign.filter(part => part.length > 0);
            filteredParts.forEach(filteredPart => {
                finalizedUrlParts.push(filteredPart.toLowerCase().trim());
            });
        }
        finalizedUrlParts.push(urlPart);
    });
    finalizedUrlParts.forEach((urlPart) => {
        virtualRefiners.forEach((mainRefiner) => {
            mainRefiner.Values.forEach((refinerValue) => {
                const refinerValueKeyName = refinerValue.LeftValueBoundString?.toLowerCase().trim().split(' ')?.join('-')?.replace(/\&/g, 'and')?.replace(regExSpecialChars(requestContext.app.config.canonicalUrlspecialCharIncludeList), '-');
                if (refinerValueKeyName && refinerValueKeyName === urlPart) {
                    virtualRefinerValues.push(refinerValue);
                }
            });
        });
    });

    return virtualRefinerValues;
}

/**
 * For navigation menu links, it will remove keywords configured in global configurations from the navigation link
 * and clp link and return url path string
 * @param link
 * @param requestContext
 * @returns string
 */
export const navLinkKeywordRemoval = (link: string, requestContext: IRequestContext): string => {
    // Using the rest operator in case there is a weirdly formatted URL with multiple question marks.
    const [linkBase, ...linkParams] = link?.split('?');

    // Only apply to category links and product links, which end in .c or .p. Check that a keyword is configured.
    if (!linkBase.endsWith('.c') && !linkBase.endsWith('.p') || !requestContext.app.config) {
        return link;
    }

    // Separate out url parts split on forward /
    let urlParts = linkBase?.split('\/');

    // Remove unwanted keywords from url
    urlParts = removeKeywords(urlParts, requestContext);

    // Glue the URL params back together
    const linkParam = linkParams.map(param => `?${param}`)?.join('');

    // Rebuild URL by joining with forward slash and appending any URL parameters
    const filteredLink = `${urlParts?.join('/')}${linkParam}`;
    return `${filteredLink}`;
};
export const regExSpecialChars = (includeConfigList: string): RegExp => {
    let includeConfig = '';
    if (includeConfigList !== undefined && includeConfigList !== '') {
        includeConfig = includeConfigList?.trim().split(',')?.join('\\\\');
        includeConfig = includeConfig && includeConfig !== '' ? `\\\\${includeConfig}` : '';
        const pattern = `[^\\w\\&\\|${includeConfig}]`;
        // eslint-disable-next-line security/detect-non-literal-regexp
        return new RegExp(pattern, 'gi');
    } else {

        const pattern = `[^\\w\\&\\|]`;
        // eslint-disable-next-line security/detect-non-literal-regexp
        return new RegExp(pattern, 'gi');
    }
};
