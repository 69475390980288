// tslint:disable
import * as EntityClasses from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';
import * as Entities from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

/**
 * MFIGetBonusItemsParameters entity interface.
 */
export interface IMFIGetBonusItemsParameters {
    CartId?: string;
    Coupons?: string[];
    // Id: number;
    ExtensionProperties?: Entities.CommerceProperty[];
}

/**
 * MFIBonusItemsDetailsEntity entity interface.
 */
export interface IMFIBonusItemsDetailsEntity {
    Coupon?: string;
    IsBonusCoupon?: string;
    CouponMessage?: string;
    Items?: IMFIQualifyingItemDetails[];
    Id: number;
    ExtensionProperties?: Entities.CommerceProperty[];
}

/**
 * MFIQualifyingItemDetails entity interface.
 */
export interface IMFIQualifyingItemDetails {
    QualifyingItemId?: string;
    QualifyingProductId?: string;
    BonusProductQty: number;
    BonusItems?: IMFIBonusItemDetails[];
    }

    /**
    * MFIBonusItemDetails entity interface.
    */
    export interface IMFIBonusItemDetails {
    QualifyingProductId?: string;
    QualifyingItemId?: string;
    BonusItemId?: string;
    BonusProductId?: string;
    Name?: string;
    AdditionToCart?: string;
    AdjustedPrice: number;
    BasePrice: number;
    CustomerContextualPrice: number;
    DiscountAmount: number;
    DiscountTypeValue: number;
    DiscountType?: string;
    PrimaryImageUrl: string;
    ProductTypeValue: number;
    DealPriceOrDiscountPercentage: number;
    BonusCalculatedDiscountPrice: number;
    Coupon?: string;
    }

    /**
    * MFIGetBonusCouponsDetailsResponse entity interface.
    */
    export interface IMFIGetBonusCouponsDetailsResponse {
    BonusCoupons?: IMFIBonusCouponsDetail[];
}

/**
 * MFIBonusItemsResponse entity interface.
 */
export interface IMFIBonusItemsResponse {
    BonusItemsDetails?: IMFIBonusItemsDetailsEntity[];
    Message?: string;
}

/**
 * MFIBonusCouponsDetail entity interface.
 */
export interface IMFIBonusCouponsDetail {
    BonusCoupon?: string;
    BonusCouponMessage?: string;
    ChildCoupons?: string[];
}

/**
 * MFIGetBonusItemsParameters entity class.
 */
export class MFIGetBonusItemsParametersExtensionClass implements IMFIGetBonusItemsParameters {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public CartId: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Coupons: string[];

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Id: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ExtensionProperties: Entities.CommerceProperty[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.CartId = odataObject.CartId;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Coupons = undefined;
        if (odataObject.Coupons) {
            this.Coupons = [];
            for (let i = 0; i < odataObject.Coupons.length; i++) {
                if (odataObject.Coupons[i]) {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.Coupons[i] = odataObject.Coupons[i];
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.Coupons[i] = undefined;
                }
            }
        }

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Id = odataObject.Id;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
            this.ExtensionProperties = [];
            for (let i = 0; i < odataObject.ExtensionProperties.length; i++) {
                if (odataObject.ExtensionProperties[i]) {
                    if (odataObject.ExtensionProperties[i]['@odata.type']) {
                        let className: string = odataObject.ExtensionProperties[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i]);
                        }
                    } else {
                        this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.ExtensionProperties[i] = undefined;
                }
            }
        }
    }
}

/**
 * MFIBonusItemsDetailsEntity entity class.
 */
export class MFIBonusItemsDetailsEntityExtensionClass implements IMFIBonusItemsDetailsEntity {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Coupon: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public IsBonusCoupon: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public CouponMessage: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Items: IMFIQualifyingItemDetails[];

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Id: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ExtensionProperties: Entities.CommerceProperty[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Coupon = odataObject.Coupon;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.IsBonusCoupon = odataObject.IsBonusCoupon;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.CouponMessage = odataObject.CouponMessage;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Items = undefined;
        if (odataObject.Items) {
            this.Items = [];
            for (let i = 0; i < odataObject.Items.length; i++) {
                if (odataObject.Items[i]) {
                    if (odataObject.Items[i]['@odata.type']) {
                        let className: string = odataObject.Items[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.Items[i] = new EntityClasses[className](odataObject.Items[i]);
                        }
                    } else {
                        this.Items[i] = new MFIQualifyingItemDetailsExtensionClass(odataObject.Items[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.Items[i] = undefined;
                }
            }

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Id = odataObject.Id;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.ExtensionProperties = undefined;
            if (odataObject.ExtensionProperties) {
                this.ExtensionProperties = [];
                for (let i = 0; i < odataObject.ExtensionProperties.length; i++) {
                    if (odataObject.ExtensionProperties[i]) {
                        if (odataObject.ExtensionProperties[i]['@odata.type']) {
                            let className: string = odataObject.ExtensionProperties[i]['@odata.type'];
                            className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                            if (EntityClasses[className]) {
                                this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i]);
                            }
                        } else {
                            this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
                        }
                    } else {
                        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                        this.ExtensionProperties[i] = undefined;
                    }
                }
            }
        }
    }
}

/**
 * MFIQualifyingItemDetails entity class.
 */
export class MFIQualifyingItemDetailsExtensionClass implements IMFIQualifyingItemDetails {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public QualifyingItemId: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public QualifyingProductId: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public BonusProductQty: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public BonusItems: IMFIBonusItemDetails[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.QualifyingItemId = odataObject.QualifyingItemId;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.QualifyingProductId = odataObject.QualifyingProductId;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.BonusProductQty = odataObject.BonusProductQty ? parseFloat(odataObject.BonusProductQty) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.BonusItems = undefined;
        if (odataObject.BonusItems) {
            this.BonusItems = [];
            for (let i = 0; i < odataObject.BonusItems.length; i++) {
                if (odataObject.BonusItems[i]) {
                    if (odataObject.BonusItems[i]['@odata.type']) {
                        let className: string = odataObject.BonusItems[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.BonusItems[i] = new EntityClasses[className](odataObject.BonusItems[i]);
                        }
                    } else {
                        this.BonusItems[i] = new MFIBonusItemDetailsExtensionClass(odataObject.BonusItems[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.BonusItems[i] = undefined;
                }
            }
        }
    }
}

/**
 * MFIBonusItemDetails entity class.
 */
export class MFIBonusItemDetailsExtensionClass implements IMFIBonusItemDetails {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public BonusItemId: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public BonusProductId: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Name: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public AdditionToCart: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public AdjustedPrice: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public BasePrice: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public CustomerContextualPrice: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public DiscountAmount: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public DiscountTypeValue: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public DiscountType: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public DealPriceOrDiscountPercentage: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public BonusCalculatedDiscountPrice: number;

    public PrimaryImageUrl: string;

    public ProductTypeValue: number;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.BonusItemId = odataObject.BonusItemId;

        this.PrimaryImageUrl = odataObject.PrimaryImageUrl;

        this.ProductTypeValue = odataObject.ProductTypeValue;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.BonusProductId = odataObject.BonusProductId;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Name = odataObject.Name;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.AdditionToCart = odataObject.AdditionToCart;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.AdjustedPrice = odataObject.AdjustedPrice ? parseFloat(odataObject.AdjustedPrice) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.BasePrice = odataObject.BasePrice ? parseFloat(odataObject.BasePrice) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.CustomerContextualPrice = odataObject.CustomerContextualPrice ? parseFloat(odataObject.CustomerContextualPrice) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.DiscountAmount = odataObject.DiscountAmount ? parseFloat(odataObject.DiscountAmount) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.DiscountTypeValue = odataObject.DiscountTypeValue;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.DiscountType = odataObject.DiscountType;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.DealPriceOrDiscountPercentage = odataObject.DealPriceOrDiscountPercentage
            ? parseFloat(odataObject.DealPriceOrDiscountPercentage)
            : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.BonusCalculatedDiscountPrice = odataObject.BonusCalculatedDiscountPrice
            ? parseFloat(odataObject.BonusCalculatedDiscountPrice)
            : 0;
    }
}

/**
 * MFIGetBonusCouponsDetailsResponse entity class.
 */
export class MFIGetBonusCouponsDetailsResponseExtensionClass implements IMFIGetBonusCouponsDetailsResponse {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public BonusCoupons: IMFIBonusCouponsDetail[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.BonusCoupons = undefined;
        if (odataObject.BonusCoupons) {
            this.BonusCoupons = [];
            for (let i = 0; i < odataObject.BonusCoupons.length; i++) {
                if (odataObject.BonusCoupons[i]) {
                    if (odataObject.BonusCoupons[i]['@odata.type']) {
                        let className: string = odataObject.BonusCoupons[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.BonusCoupons[i] = new EntityClasses[className](odataObject.BonusCoupons[i]);
                        }
                    } else {
                        this.BonusCoupons[i] = new MFIBonusCouponsDetailExtensionClass(odataObject.BonusCoupons[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.BonusCoupons[i] = undefined;
                }
            }
        }
    }
}

/**
 * MFIBonusItemsResponse entity class.
 */
export class MFIBonusItemsResponseExtensionClass implements IMFIBonusItemsResponse {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public BonusItemsDetails: IMFIBonusItemsDetailsEntity[];

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Message: string;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.BonusItemsDetails = undefined;
        if (odataObject.BonusItemsDetails) {
            this.BonusItemsDetails = [];
            for (let i = 0; i < odataObject.BonusItemsDetails.length; i++) {
                if (odataObject.BonusItemsDetails[i]) {
                    if (odataObject.BonusItemsDetails[i]['@odata.type']) {
                        let className: string = odataObject.BonusItemsDetails[i]['@odata.type'];
                        className = className.substr(className.lastIndexOf('.') + 1).concat('Class');
                        if (EntityClasses[className]) {
                            this.BonusItemsDetails[i] = new EntityClasses[className](odataObject.BonusItemsDetails[i]);
                        }
                    } else {
                        this.BonusItemsDetails[i] = new MFIBonusItemsDetailsEntityExtensionClass(odataObject.BonusItemsDetails[i]);
                    }
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.BonusItemsDetails[i] = undefined;
                }
            }
        }

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Message = odataObject.Message;
    }
}

/**
 * MFIBonusCouponsDetail entity class.
 */
export class MFIBonusCouponsDetailExtensionClass implements IMFIBonusCouponsDetail {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public BonusCoupon: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public BonusCouponMessage: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ChildCoupons: string[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.BonusCoupon = odataObject.BonusCoupon;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.BonusCouponMessage = odataObject.BonusCouponMessage;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ChildCoupons = undefined;
        if (odataObject.ChildCoupons) {
            this.ChildCoupons = [];
            for (let i = 0; i < odataObject.ChildCoupons.length; i++) {
                if (odataObject.ChildCoupons[i]) {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.ChildCoupons[i] = odataObject.ChildCoupons[i];
                } else {
                    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
                    this.ChildCoupons[i] = undefined;
                }
            }
        }
    }
}
