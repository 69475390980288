// sample-state.ts
import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    IAny,
    ICreateActionContext,
    IGeneric
} from '@msdyn365-commerce/core';
export interface IZipcodeValidation {
    isZipcodeValid: boolean;
}

/**
 * ZipCodeState - action input
 */
export class ZipCodeValidationInput implements IActionInput {
    public getCacheKey = () => `ZipCodeValidation`;
    public getCacheObjectType = () => 'ZipCodeValidation';
    public dataCacheType = (): CacheType => 'request';
}

/**
 * ZipCodeState - action
 */
export async function ZipcodeValidationAction(input: ZipCodeValidationInput, ctx: IActionContext): Promise<IZipcodeValidation> {
    return { isZipcodeValid: false };
}

/**
 * ZipCodeState - create new input for create action
 */
const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    return new ZipCodeValidationInput();
};

/**
 * ZipCodeState - create action
 */
export default createObservableDataAction<IZipcodeValidation>({
    id: 'ZipCodeValidation',
    action: <IAction<IZipcodeValidation>>ZipcodeValidationAction,
    input: createInput
});
