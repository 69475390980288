import * as Msdyn365 from '@msdyn365-commerce/core';
import { IMFISubmitCartInformationResponse, ISubmitCartInformationRequest, ISubmitCartInformationResponse } from './progressive-proxy-files/ProgressiveDataServiceEntities.g';
import { mFISubmitCartInformationAsync } from './progressive-proxy-files/ProgressiveDataActionExtension.g';


/**
 * SubmitCartInformation Input Action
 */
export class SubmitCartInformationInput extends Msdyn365.CommerceEntityInput implements Msdyn365.IActionInput {
    public submitCartInformation: ISubmitCartInformationRequest;

    constructor(submitCartInformation: ISubmitCartInformationRequest) {
        super({ shouldCacheOutput: true, cacheObjectType: 'SubmitCartInformation', cacheKey: 'SubmitCartInformation' });
        this.submitCartInformation = submitCartInformation;
    }

    public getCacheKey = () => `SubmitCartInformation`;
    public getCacheObjectType = () => 'SubmitCartInformation';
    public dataCacheType = (): Msdyn365.CacheType => 'none';
}

const createInput = (args: Msdyn365.ICreateActionContext): Msdyn365.IActionInput => {
    const submitCartInformation: ISubmitCartInformationRequest = { ApplicationId: '', MerchantTransactionId: '', Deposit: 0, SalesTax: 0, CartTotal: 0, Store: { ProgressiveMerchantId: 0 }, Items: []};
    return new SubmitCartInformationInput(submitCartInformation);
};

/**
 * SubmitCartInformationAction function receives cart information and application ID and returns CartId
 */
export async function SubmitCartInformationAction(input: SubmitCartInformationInput, ctx: Msdyn365.IActionContext): Promise<ISubmitCartInformationResponse | null> {
    const { submitCartInformation } = input;
    try {
        const cartInformation: IMFISubmitCartInformationResponse = await mFISubmitCartInformationAsync({ callerContext: ctx }, submitCartInformation);
        return cartInformation.SubmitCartInformation ?? null;
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
}

export const ISubmitCartInformationAction = Msdyn365.createObservableDataAction({
    action: <Msdyn365.IAction<ISubmitCartInformationResponse>>SubmitCartInformationAction,
    input: createInput,
    id: 'SubmitCartInformation'
});