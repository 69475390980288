// WmapAddress-state.ts
import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
export interface IWmapAddressState {
    preFilledAddress: any;
    isAddressUpdate: boolean;
}

/**
 * WmapAddressState - action input
 */
export class WmapAddressStateInput implements IActionInput {
    constructor() {}
    public getCacheKey = () => `WmapAddressState`;
    public getCacheObjectType = () => 'WmapAddressState';
    public dataCacheType = (): CacheType => 'application';
}

/**
 * WmapAddressState - action
 */
async function action(input: WmapAddressStateInput, ctx: IActionContext): Promise<IWmapAddressState> {
    return { preFilledAddress: {}, isAddressUpdate: false };
}

/**
 * WmapAddressState - create new input for create action
 */
const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    return new WmapAddressStateInput();
};

/**
 * WmapAddressState - create action
 */
export default createObservableDataAction<IWmapAddressState>({
    id: 'WmapAddressState',
    action: <IAction<IWmapAddressState>>action,
    input: createInput
});