import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
export interface IPromoCodeAppliedOnCartState {
    isInProcess?: boolean;
}

/**
 * SampleState - action input
 */
export class PromoCodeAppliedOnCartInput implements IActionInput {
    public getCacheKey = () => `PromoCodeAppliedOnCartInput`;
    public getCacheObjectType = () => 'PromoCodeAppliedOnCartInput';
    public dataCacheType = (): CacheType => 'request';
}

/**
 * SampleState - action
 */
export async function updatePromoCodeAppliedOnCartProgressAction(input: PromoCodeAppliedOnCartInput, ctx: IActionContext): Promise<IPromoCodeAppliedOnCartState> {
    return { isInProcess: undefined };
}

/**
 * SampleState - create new input for create action
 */
const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    return new PromoCodeAppliedOnCartInput();
};

/**
 * SampleState - create action
 */
export default createObservableDataAction<IPromoCodeAppliedOnCartState>({
    id: 'PromoCodeAppliedOnCartInput',
    action: <IAction<IPromoCodeAppliedOnCartState>>updatePromoCodeAppliedOnCartProgressAction,
    input: createInput
});