import * as Msdyn365 from '@msdyn365-commerce/core';
import { IGetLeaseContractRequest, mFIGetLeaseContractAsync, IGetLeaseContractResponse } from './progressive-proxy-files';

/**
 * GetLeaseContract Input Action
 */

export class GetLeaseContractInput extends Msdyn365.CommerceEntityInput implements Msdyn365.IActionInput {
    public leaseContractPayload: IGetLeaseContractRequest;

    constructor(leaseContract: IGetLeaseContractRequest) {
        super({ shouldCacheOutput: true, cacheObjectType: 'GetLeaseContract', cacheKey: 'GetLeaseContract' });
        this.leaseContractPayload = leaseContract;
    }

    public getCacheKey = () => `GetLeaseContract`;
    public getCacheObjectType = () => 'GetLeaseContract';
    public dataCacheType = (): Msdyn365.CacheType => 'application';
}

const createInput = (args: Msdyn365.ICreateActionContext): Msdyn365.IActionInput => {
    return new GetLeaseContractInput({ ApplicationId: '' });
};

/**
 * GetLeaseContractAction function receives applicationId and returns Iframe url to sign the lease contract
 */
export async function getLeaseContractAction(input: GetLeaseContractInput, ctx: Msdyn365.IActionContext): Promise<IGetLeaseContractResponse | null> {
    try {
        const leaseContractResponse = await mFIGetLeaseContractAsync({ callerContext: ctx }, input.leaseContractPayload);
        return leaseContractResponse.LeaseContacts ?? null;
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
}

export const IGetLeaseContractAction = Msdyn365.createObservableDataAction({
    action: <Msdyn365.IAction<IGetLeaseContractResponse>>getLeaseContractAction,
    input: createInput,
    id: 'GetLeaseContract'
});