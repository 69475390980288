/* tslint:disable: no-duplicate-imports */
import { IPageSummaryData } from '@msdyn365-commerce-modules/page-summary';
import { buildCacheKeyWithUrlTokens, CurrentCategoryInput, getCategoryUrl, getCurrentCategory, removeDomainQspFromUrl } from '@msdyn365-commerce-modules/retail-actions';
import { CategoryHierarchy, IProductRefinerHierarchy } from '@msdyn365-commerce/commerce-entities';
import {
    CacheType,
    createObservableDataAction,
    getFriendlyName,
    IAction,
    IActionContext,
    IActionInput,
    ICreateActionContext,
    IRequestContext,
    IAny,
    IGeneric
} from '@msdyn365-commerce/core';
import flatten from 'lodash/flatten';
// VSI customization: 7924 Start
import getHtmlParserValue from '../../Utilities/get-html-parser-value';
import { canonicalURL, getSortedVirtualRefinerConfig, getVirtualRefinerNames, regExSpecialChars, removeKeywords } from '../../Utilities/plp-url-utils';
import getSEOData, { GetSeoDataInput } from '../../data-actions/get-seo-data.action';
import { getProductSearchRefinersAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { ProductRefiner } from '@msdyn365-commerce/retail-proxy';
import { IMfrmCategoryPageSummaryConfig } from './mfrm-category-page-summary.props.autogenerated';
// VSI customization: 7924 End

/** Category Page Summary Input */
export class MFRMCategoryPageSummaryInput implements IActionInput {
    public requestContext: IRequestContext;
    public config: IMfrmCategoryPageSummaryConfig;
    constructor(config: IMfrmCategoryPageSummaryConfig, requestContext: IRequestContext) {
        this.config = config || {};
        this.requestContext = requestContext;
    }

    public getCacheObjectType = (): string => 'MFRMCategoryPageSummary';
    public getCacheKey = (): string => `${buildCacheKeyWithUrlTokens('MFRMCategoryPageSummary', this.requestContext)}-${this.requestContext?.url?.requestUrl?.pathname?.toLowerCase()}`;
    public dataCacheType = (): CacheType => 'application';
}

const createGetCurrentCategoryInput = (inputData: IActionContext): CurrentCategoryInput => {
    if (inputData && inputData.requestContext) {
        return new CurrentCategoryInput(inputData.requestContext);
    }

    throw new Error('Please specify categoryId query string in request.');
};

/**
 * Get skip count.
 * @param inputData
 */
function getSkipCount(inputData: ICreateActionContext<IGeneric<IAny>, IGeneric<IAny>>): string | undefined {
    return inputData && inputData.requestContext && inputData.requestContext.query && inputData.requestContext.query.skip;
}

const _getSEOMetaTagValue = (currentCategory: CategoryHierarchy, keyValue: string) => {
    return currentCategory?.ExtensionProperties?.find((attr) => attr.Key === keyValue)?.Value?.StringValue;
};

const _getActiveRefiners = (refiners: IProductRefinerHierarchy[], requestContext: IRequestContext, CategoryHierarchy: any): string[] | undefined => {
    // get refiners from URL
    const configVirtualRefinerNames = getVirtualRefinerNames(requestContext, false);
    const filteredRefiners = refiners && refiners.filter((refiner) => {
        return refiner.KeyName && configVirtualRefinerNames.indexOf(refiner.KeyName.toLowerCase()) !== -1;
    });
    // get category slug
    let slug = (CategoryHierarchy && CategoryHierarchy.Slug) || '';
    let slugArray: string[];
    let pathArray: string[];
    const sortedConfigValues = getSortedVirtualRefinerConfig(requestContext, false);
    if (sortedConfigValues && slug) {
        slugArray = slug.split('/').splice(1);
        // remove keywords form slugArray
        slugArray = removeKeywords(slugArray, requestContext);
        // FIX reset slug to without keywords
        slug = slugArray.join('/');
        // path of URL
        let path = requestContext.url.requestUrl.pathname.toLowerCase();
        // remove category id {categoryid}.c
        const categoryIdToken = requestContext.urlTokens.recordId;
        const categoryId = categoryIdToken ? `${categoryIdToken}.c` : undefined;

        if (categoryId) {
            path = path?.replace(`/${categoryId}`, '');
        }
        // remove category slug
        pathArray = removeKeywords(path.split('\/').splice(1), requestContext);
        path = pathArray.join('\/');
        const removedCategorySlug = path.split(slug).splice(1)[0];
        let refinerParts: string[] = [];
        if (removedCategorySlug !== '') {
            refinerParts = decodeURIComponent(removedCategorySlug).split('/');
        }
        if (refinerParts && refinerParts.length > 0 && slugArray && slugArray.length > 0) {
            // remove duplicates if any from the first url part
            slugArray.forEach(_slug => {
                refinerParts.forEach(refinerPart => {
                    const index = slugArray.indexOf(refinerPart);
                    if (index !== -1) {
                        slugArray.splice(index, 1);
                    }
                });
            });
        }
        const finalRefinerParts: string[] = [];
        refinerParts.forEach(refinerPart => {
            refinerPart.split('|').map(item => finalRefinerParts.push(item.includes('-') ? item : item.split('-').join(' ')));
        });
        refinerParts = finalRefinerParts && flatten(finalRefinerParts);
        // setup object with refiner values
        const sortedValues: { [key: string]: string[] } = {};
        filteredRefiners?.forEach(refinerSet => {
            let refinerName = refinerSet.KeyName;
            if (refinerName) {
                refinerName = refinerName.toLowerCase();
                sortedValues[refinerName] = []; // size, brand, price
            }
            refinerSet.Values.forEach((refiner) => {
                refinerParts.forEach((refinerPart) => {
                    if (refinerName && refiner.LeftValueBoundString &&
                        (refiner.LeftValueBoundString.toLowerCase()?.replace(/\&/g, 'and')?.replace(regExSpecialChars(requestContext.app.config.canonicalUrlspecialCharIncludeList), '-') === refinerPart.toLocaleLowerCase().split('-').join(' ')
                            || refiner.LeftValueBoundString.toLowerCase()?.replace(/\&/g, 'and')?.replace(regExSpecialChars(requestContext.app.config.canonicalUrlspecialCharIncludeList), '-') === refinerPart.toLocaleLowerCase())) {
                        sortedValues[refinerName].push(refiner.LeftValueBoundString);
                    } else if (refinerName && refiner.LeftValueBoundString &&
                        refiner.LeftValueBoundString.toLowerCase() === refinerPart.toLowerCase().split('-').join(' ')) {
                        sortedValues[refinerName].push(refinerPart);
                    }
                });
            });
        });
        // alpha sort
        // @ts-ignore
        for (const sortedValue of Object.keys(sortedValues)) {
            sortedValues[sortedValue].length > 0 &&
                sortedValues[sortedValue].sort((a: string, b: string) => {
                    if (a < b) {
                        return -1;
                    }
                    if (a > b) {
                        return 1;
                    }
                    return 0;
                });
        }
        // @ts-ignore
        const finalRefiners: string[] = [];
        sortedConfigValues.forEach(sortedConfigValue => {
            const sortedRefinerName = sortedConfigValue.refinerName.toLowerCase();
            if (sortedValues[sortedRefinerName] !== undefined && sortedValues[sortedRefinerName].length > 0) {
                // @ts-ignore
                finalRefiners.push(sortedValues[sortedRefinerName].join(' '));
            }
        });
        return finalRefiners;
    }
    return undefined;
};

async function getAllRefinersAction(ctx: IActionContext): Promise<IProductRefinerHierarchy[]> {
    const searchCriteria = {
        SearchCondition: '*',
        Context: {
            ChannelId: ctx.requestContext.apiSettings.channelId,
            CatalogId: ctx.requestContext.apiSettings.catalogId
        }
    };
    return getProductSearchRefinersAsync({ callerContext: ctx }, searchCriteria).then(
        (productRefiners: ProductRefiner[]) => {
            if (!productRefiners) {
                ctx.telemetry.error('[getRefinersByCriteriaAction] returned no refiners');
                return <IProductRefinerHierarchy[]>[];
            }

            const result = (productRefiners || []).map((productRefiner: ProductRefiner) => <IProductRefinerHierarchy>productRefiner);

            if (result.length === 0) {
                ctx.telemetry.error('[getRefinersByCriteriaAction] returned no IProductRefinerHierarchy objects');
                return <IProductRefinerHierarchy[]>[];
            }

            return result;
        }
    ).catch((err) => {
        console.log('[getRefinersByCriteriaAction Category-page-summary] returned no IProductRefinerHierarchy objects', err);
        ctx.telemetry.error(`[getRefinersByCriteriaAction Category-page-summary] returned no IProductRefinerHierarchy objects ${err}`);
        return <IProductRefinerHierarchy[]>[];
    });
}

async function getFallbackTitle(context: IActionContext, requestContext: IRequestContext, currentCategory: any): Promise<string> {
    let activeRefinerList: string[] | undefined = [];
    return getAllRefinersAction(context).then(refiners => {
        activeRefinerList = _getActiveRefiners(refiners, requestContext, currentCategory);
        const concateTitle = `${activeRefinerList && activeRefinerList?.length > 0 ? activeRefinerList.length === 1 ? ` ${activeRefinerList[0]} ` : ` ${activeRefinerList?.join(' ')} ` : ''}`;
        const fallbackTitle = concateTitle && `Shop for${concateTitle}Products | Mattress Firm`;
        return fallbackTitle;
    }).catch((err) => {
        console.log('[getFallbackTitle Category-page-summary] has some errors', err);
        context.telemetry.error(`[getFallbackTitle Category-page-summary] has some errors- ${err}`);
        return "";
    });
}

const action = async (input: MFRMCategoryPageSummaryInput, context: IActionContext): Promise<IPageSummaryData> => {
    const { config } = input;
    const seoInput = new GetSeoDataInput(context.requestContext.app.config.seoDataUrl || 'https://files-us-prod.cms.commerce.dynamics.com/cms/api/fknscsckgq/binary/MA5cA7');
    const seoData = await getSEOData(seoInput, context);
    const seoPageTitle = seoData && seoData['SEO State'] === 'Active' && seoData['Title Tag'];
    const seoPageMetaData = seoData && seoData['SEO State'] === 'Active' && seoData['Meta Description'];
    let currentCategory: any;
    try {
        currentCategory = await getCurrentCategory(createGetCurrentCategoryInput(context), context);
    } catch (e) {
        // Do nothing, if the action fails fallback to values defined in data
    }
    if (currentCategory) {
        let categoryUrl: string | undefined;
        let fallbackTitle;
        if (!seoPageTitle) {
            fallbackTitle = await getFallbackTitle(context, input.requestContext, currentCategory);
        }
        const pageTitle = seoPageTitle || fallbackTitle || _getSEOMetaTagValue(currentCategory, 'CategoryPageTitle');
        const pageTitleParser = getHtmlParserValue(pageTitle);

        const fallBackMeta = 'Find comfortable mattresses, high quality beds, and accessories from top brands all at great low prices at Mattress Firm';
        const categoryPageMetaData = seoPageMetaData || fallBackMeta || _getSEOMetaTagValue(currentCategory, 'CategoryPageMetaData');
        const categoryPageMetaDataParser = getHtmlParserValue(categoryPageMetaData);
        try {
            // reset category's name neutralizedName
            const localName = getFriendlyName(context.requestContext.locale, currentCategory.NameTranslations);
            const neutralizedName = currentCategory.NeutralizedName || currentCategory.Name;
            currentCategory.Name = localName;
            currentCategory.NeutralizedName = neutralizedName;
            categoryUrl = getCategoryUrl(currentCategory);
            // @ts-ignore - TODO: property exits in new version of SDK. Remove once released.
            const canonicalDomain = context.requestContext.canonicalDomain;
            if (categoryUrl && canonicalDomain) {
                categoryUrl = canonicalURL(context.requestContext).toLocaleLowerCase();
                /**
                 * Changes from v30
                 */
                const skipCount = getSkipCount(context);
                const Url = new URL(categoryUrl);
                const urlParams: URLSearchParams = new URLSearchParams(Url.search);
                if (skipCount) {
                    urlParams.set('skip', skipCount);
                }
                Url.search = urlParams.toString();
                categoryUrl = Url.toString();
                categoryUrl = removeDomainQspFromUrl(categoryUrl, context.requestContext);
            } else {
                categoryUrl = undefined;
            }
        } catch (e) {
            categoryUrl = undefined;
        }
        return {
            title: pageTitleParser || currentCategory.Name,
            description: categoryPageMetaDataParser || '',
            sharingImageUrl: currentCategory.OfflineImage,
            canonicalUrl: categoryUrl,
            faviconUrl: config && config.faviconUrl
        };
        // If the action fails fallback to values defined from data
    } else if (config) {
        return {
            title: config.title,
            sharingImageUrl: config.sharingImage && config.sharingImage.src,
            faviconUrl: config && config.faviconUrl
        };
    } else {
        return {};
    }
};

export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/page-summary/category-page-summary',
    action: <IAction<IPageSummaryData>>action,
    input: (args: ICreateActionContext) => {
        return new MFRMCategoryPageSummaryInput(<IMfrmCategoryPageSummaryConfig>args.config, args.requestContext);
    }
});
